import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import { ROUTES_PATH } from "constants/routesPath";
import { useCookies } from "react-cookie";
import { fetchUserInfo } from "redux/asyncActions/fetchUserInfo";
import { setAuthKey } from "redux/slices/userSlice";
import { fetchService } from "redux/asyncActions/fetchService";

const Wrapper = ({ children, white = false }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [cookies] = useCookies(["auth_key", "user_id"]);
  const [wheel, setWheel] = useState(false);

  useEffect(() => {
    if (pathname.includes("account")) {
      if (cookies.auth_key === "") {
        navigate(ROUTES_PATH.HOME);
      }
    }
  }, [pathname]);

  const fetchUserData = () => {
    const auth_key = cookies.auth_key;
    const user_id = cookies.user_id;

    if (auth_key && user_id) {
      dispatch(fetchUserInfo({ auth_key, user_id }));
      dispatch(setAuthKey(auth_key));
    }
  };

  useEffect(() => {
    fetchUserData();
  }, [cookies]);

  useEffect(() => {
    fetchUserData();
    dispatch(fetchService());
  }, []);

  useEffect(() => {
    if (pathname.includes("wheel")) {
      setWheel(true);
    } else {
      setWheel(false);
    }
  }, [pathname]);

  return (
    <div
      className={`wrapper ${wheel ? "wrapper--wheel" : ""} ${
        white ? "wrapper--white" : ""
      }`}
    >
      {children}
    </div>
  );
};

export default Wrapper;
