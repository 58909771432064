import React from "react";

const Icon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      id="_Слой_2"
      data-name="Слой 2"
      viewBox="0 0 1625.88 2052.12"
      height={107}
    >
      <defs>
        <style
          dangerouslySetInnerHTML={{
            __html:
              "\n      .cls-1 {\n        fill: #00a6d8;\n      }\n\n      .cls-1, .cls-2, .cls-3, .cls-4, .cls-5, .cls-6 {\n        fill-rule: evenodd;\n      }\n\n      .cls-2 {\n        fill: #0077b3;\n      }\n\n      .cls-3 {\n        fill: #00adb1;\n      }\n\n      .cls-4 {\n        fill: #fe0;\n      }\n\n      .cls-7 {\n        fill: #2d373b;\n      }\n\n      .cls-5 {\n        fill: #9e75b2;\n      }\n\n      .cls-6 {\n        fill: #008eca;\n      }\n    ",
          }}
        />
      </defs>
      <g id="Layer_1" data-name="Layer 1">
        <g>
          <g>
            <path
              className="cls-7"
              d="M632.29,1826.82c-4.16,2.08-12.48,4.16-23.14,4.16-24.69,0-43.28-15.6-43.28-44.32s18.59-46.01,45.75-46.01c10.92,0,17.81,2.34,20.8,3.9l-2.73,9.23c-4.29-2.08-10.4-3.64-17.68-3.64-20.54,0-34.18,13.13-34.18,36.13,0,21.45,12.35,35.22,33.66,35.22,6.89,0,13.91-1.43,18.46-3.64l2.34,8.97Z"
            />
            <path
              className="cls-7"
              d="M689.09,1751.69h-26.64v-9.62h64.86v9.62h-26.77v77.99h-11.44v-77.99Z"
            />
            <path
              className="cls-7"
              d="M773.7,1742.08v36.91c0,13.91-.26,25.34-1.04,37.04l.26,.13c4.42-9.1,9.88-18.59,16.25-28.98l28.33-45.1h11.31v87.6h-10.66v-37.3c0-14.17,.13-24.57,1.04-35.61l-.39-.13c-4.29,9.62-10.27,19.76-16.25,29.5l-27.42,43.54h-12.09v-87.6h10.66Z"
            />
            <path
              className="cls-7"
              d="M932.13,1742.08v87.6h-11.44v-78.12h-28.98v26.52c0,18.98-.78,40.16-13.13,48.48-3.25,2.08-8.32,4.16-13.39,4.16l-1.43-9.1c3.51-.65,7.15-2.73,9.23-4.68,7.02-6.89,7.67-23.92,7.67-37.95v-36.91h51.47Z"
            />
            <path
              className="cls-7"
              d="M989.05,1742.08v32.75c2.6-.39,7.8-.65,10.79-.65,17.94,0,33.79,7.93,33.79,27.29,0,8.19-2.6,14.17-6.76,18.72-7.15,7.54-19.37,10.27-31.45,10.27-7.93,0-13.91-.52-17.68-1.04v-87.34h11.31Zm0,78.89c2.6,.52,5.46,.65,9.36,.65,12.61,0,23.4-6.11,23.4-19.5s-11.05-18.98-23.53-18.98c-2.86,0-6.37,.26-9.23,.65v37.17Z"
            />
          </g>
          <g>
            <path
              className="cls-7"
              d="M149.14,1953.08h-26.64v-9.62h64.86v9.62h-26.77v77.99h-11.44v-77.99Z"
            />
            <path
              className="cls-7"
              d="M297.95,1986.35c0,30.15-18.33,46.14-40.68,46.14s-39.38-17.94-39.38-44.45c0-27.81,17.29-46.01,40.68-46.01s39.38,18.33,39.38,44.32Zm-67.98,1.43c0,18.72,10.14,35.48,27.94,35.48s28.07-16.51,28.07-36.39c0-17.42-9.1-35.61-27.94-35.61s-28.07,17.29-28.07,36.52Z"
            />
            <path
              className="cls-7"
              d="M338.63,1944.5c5.46-.91,12.61-1.69,21.71-1.69,11.18,0,19.37,2.6,24.56,7.28,4.81,4.16,7.67,10.53,7.67,18.33s-2.34,14.17-6.76,18.72c-5.98,6.37-15.73,9.62-26.77,9.62-3.38,0-6.5-.13-9.1-.78v35.09h-11.31v-86.56Zm11.31,42.24c2.47,.65,5.59,.91,9.36,.91,13.65,0,21.97-6.63,21.97-18.72s-8.19-17.16-20.67-17.16c-4.94,0-8.71,.39-10.66,.91v34.05Z"
            />
            <path
              className="cls-7"
              d="M480.3,1943.46v9.49h-35.22v78.12h-11.31v-87.6h46.53Z"
            />
            <path
              className="cls-7"
              d="M590.9,1986.35c0,30.15-18.33,46.14-40.68,46.14s-39.38-17.94-39.38-44.45c0-27.81,17.29-46.01,40.68-46.01s39.38,18.33,39.38,44.32Zm-67.98,1.43c0,18.72,10.14,35.48,27.94,35.48s28.07-16.51,28.07-36.39c0-17.42-9.1-35.61-27.94-35.61s-28.07,17.29-28.07,36.52Z"
            />
            <path
              className="cls-7"
              d="M631.58,1944.63c4.94-1.04,12.74-1.82,20.67-1.82,11.31,0,18.59,1.95,24.05,6.37,4.55,3.38,7.28,8.58,7.28,15.47,0,8.45-5.59,15.86-14.82,19.24v.26c8.32,2.08,18.07,8.97,18.07,21.97,0,7.54-2.99,13.26-7.41,17.55-6.11,5.59-15.99,8.19-30.28,8.19-7.8,0-13.78-.52-17.55-1.04v-86.17Zm11.31,35.87h10.27c11.96,0,18.98-6.24,18.98-14.69,0-10.27-7.8-14.3-19.24-14.3-5.2,0-8.19,.39-10.01,.78v28.2Zm0,41.98c2.21,.39,5.46,.52,9.49,.52,11.7,0,22.49-4.29,22.49-17.03,0-11.96-10.27-16.9-22.62-16.9h-9.36v33.4Z"
            />
            <path
              className="cls-7"
              d="M739.32,1943.46v32.75c2.6-.39,7.54-.65,10.4-.65,18.07,0,33.79,7.93,33.79,27.42,0,8.06-2.6,14.04-6.76,18.59-7.15,7.54-19.37,10.27-31.19,10.27-7.8,0-13.78-.52-17.55-1.04v-87.34h11.31Zm0,78.89c2.34,.52,5.2,.65,9.1,.65,12.48,0,23.27-6.37,23.27-19.63s-11.7-18.85-23.4-18.85c-4.16,0-7.54,.39-8.97,.65v37.17Zm65.38-78.89v87.6h-11.44v-87.6h11.44Z"
            />
            <path
              className="cls-7"
              d="M861.62,1943.46v36.91c0,13.91-.26,25.34-1.04,37.04l.26,.13c4.42-9.1,9.88-18.59,16.25-28.98l28.33-45.1h11.31v87.6h-10.66v-37.3c0-14.17,.13-24.57,1.04-35.61l-.39-.13c-4.29,9.62-10.27,19.76-16.25,29.5l-27.42,43.54h-12.09v-87.6h10.66Zm14.3-18.33c.52,4.03,1.82,8.06,7.93,8.06s7.41-3.77,7.93-8.06h8.71c-.65,8.58-5.85,13.91-16.9,13.91s-15.73-5.33-16.38-13.91h8.71Z"
            />
            <path
              className="cls-7"
              d="M1027.33,1943.46v78.11h40.16v-78.11h11.44v78.37l7.67,.26-.78,30.02h-8.97l-.78-21.06h-60.05v-87.6h11.31Z"
            />
            <path
              className="cls-7"
              d="M1172.11,1989.99h-34.05v31.58h37.95v9.49h-49.26v-87.6h47.31v9.49h-36v27.68h34.05v9.36Z"
            />
            <path
              className="cls-7"
              d="M1227.99,1943.46v36.65h42.37v-36.65h11.44v87.6h-11.44v-41.07h-42.37v41.07h-11.31v-87.6h11.31Z"
            />
            <path
              className="cls-7"
              d="M1344.06,1953.08h-26.64v-9.62h64.86v9.62h-26.78v77.99h-11.44v-77.99Z"
            />
            <path
              className="cls-7"
              d="M1418.01,1944.5c5.46-.91,12.61-1.69,21.71-1.69,11.18,0,19.37,2.6,24.57,7.28,4.81,4.16,7.67,10.53,7.67,18.33s-2.34,14.17-6.76,18.72c-5.98,6.37-15.73,9.62-26.77,9.62-3.38,0-6.5-.13-9.1-.78v35.09h-11.31v-86.56Zm11.31,42.24c2.47,.65,5.59,.91,9.36,.91,13.65,0,21.97-6.63,21.97-18.72s-8.19-17.16-20.67-17.16c-4.94,0-8.71,.39-10.66,.91v34.05Z"
            />
          </g>
          <g>
            <polygon
              className="cls-7"
              points="1188.7 1470.99 1134.97 1470.99 1134.97 1369.54 1096.69 1369.54 1096.69 1586.27 1134.97 1586.27 1134.97 1483.12 1188.7 1483.12 1188.7 1586.27 1227.03 1586.27 1227.03 1369.54 1188.7 1369.54 1188.7 1470.99"
            />
            <path
              className="cls-7"
              d="M1019.85,1369.54h-104.78c0,29.47-1.46,73.36-8.66,115.01-7.01,42.02-18.68,72.85-33.41,89.49h-24.55v12.23h192.64v-12.23h-21.25v-204.51Zm-116.76,204.51c13.03-19.75,22.88-49,29.33-87.14,6.31-37.04,8.39-75.86,8.66-105.24h40.42v192.38h-78.41Z"
            />
            <polygon
              className="cls-7"
              points="590.37 1369.55 546.97 1444.92 503.23 1369.55 464.9 1369.55 527.78 1477.91 464.9 1586.28 503.23 1586.28 546.97 1510.98 590.37 1586.28 628.75 1586.28 565.97 1477.91 628.75 1369.55 590.37 1369.55"
            />
            <rect
              className="cls-7"
              x="372.61"
              y="1369.54"
              width="38.43"
              height="216.73"
            />
            <path
              className="cls-7"
              d="M282.48,1458.67h-39.28v-89.13h-38.28v216.73h77.56c32.8,0,52.59-18.63,52.59-46.43v-34.59c0-27.78-19.8-46.58-52.59-46.58m13.32,84.86c0,21.74-16.01,29.81-37.58,29.81h-15.02v-101.79h15.02c21.57,0,37.58,7.96,37.58,29.91v42.07Z"
            />
            <path
              className="cls-7"
              d="M746.69,1368.08c-45.92,0-71.69,23.22-71.69,50.97v117.95c0,28.24,25.76,51.02,71.69,51.02s71.83-22.78,71.83-51.02v-117.95c0-27.51-25.84-50.97-71.83-50.97m33.07,175.73c0,19.24-16.96,29.94-33.07,29.94s-33.04-10.7-33.04-29.94v-131.48c0-19.19,16.84-29.98,33.04-29.98s33.07,10.79,33.07,29.98v131.48Z"
            />
            <polygon
              className="cls-7"
              points="1587.94 1369.55 1524.16 1533.73 1524.16 1369.55 1485.98 1369.55 1485.98 1586.28 1522.92 1586.28 1587.6 1421.66 1587.6 1586.28 1625.88 1586.28 1625.88 1369.55 1587.94 1369.55"
            />
            <rect
              className="cls-7"
              x="1529.94"
              y="1319.4"
              width="48.03"
              height="24.55"
            />
            <path
              className="cls-7"
              d="M114.14,1476.73c14.82-7.69,22.51-20.62,22.51-37.53v-19.77c0-32.41-20.01-49.88-54.63-49.88H0v216.71H81.58c47.57,0,65.48-18.15,65.48-46.89v-22.9c0-18.85-11.18-34.67-32.92-39.74m-76-94.15h18.19c22.22,0,41.22,8.37,41.22,37.43v13.68c0,28.99-18.99,37.53-41.22,37.53h-18.19v-88.64Zm69.84,153.37c0,26.15-16.76,37.38-46.68,37.38h-23.17v-88.79h28.53c22.12,0,41.31,8.52,41.31,37.67v13.73Z"
            />
            <path
              className="cls-7"
              d="M1358.62,1368.08c-45.87,0-71.74,23.22-71.74,50.97v117.95c0,28.24,25.86,51.02,71.74,51.02s71.78-22.78,71.78-51.02v-117.95c0-27.51-26.01-50.97-71.78-50.97m33.21,175.73c0,19.24-17.01,29.94-33.21,29.94s-32.99-10.7-32.99-29.94v-131.48c0-19.19,16.84-29.98,32.99-29.98s33.21,10.79,33.21,29.98v131.48Z"
            />
          </g>
          <g>
            <polygon
              className="cls-3"
              points="759.86 83.49 703.15 0 683.29 0 587.27 141.36 587.27 636.55 759.86 519.32 759.86 83.49"
            />
            <polygon
              className="cls-4"
              points="703.15 0 759.86 83.49 759.86 0 703.15 0"
            />
            <polygon
              className="cls-5"
              points="587.27 1017.15 542.47 1017.15 542.47 1043.92 759.86 1043.92 759.86 1017.75 587.27 715.79 587.27 1017.15"
            />
            <polygon
              className="cls-6"
              points="587.27 636.55 587.27 715.79 759.86 1017.75 759.86 519.32 587.27 636.55"
            />
            <polygon
              className="cls-1"
              points="542.47 0 542.47 26.77 587.27 26.77 587.27 141.36 683.29 0 542.47 0"
            />
            <path
              className="cls-5"
              d="M1082.39,146.57C1082.39,65.63,1016.46,0,935.52,0h-41.14V76.77l188.01,127.72v-57.91Z"
            />
            <polygon
              className="cls-4"
              points="962.44 381.71 1082.39 300.24 1082.39 204.49 894.38 76.77 894.38 281.53 962.44 381.71"
            />
            <path
              className="cls-5"
              d="M1001.27,438.88c48.04-24.09,81.12-73.7,81.12-131.03v-7.61l-119.95,81.48,38.83,57.17Z"
            />
            <path
              className="cls-6"
              d="M894.38,427.93v26.51h41.14c23.62,0,45.95-5.63,65.75-15.56l-38.83-57.17-68.06,46.22Z"
            />
            <polygon
              className="cls-3"
              points="894.38 281.53 894.38 427.93 962.44 381.71 894.38 281.53"
            />
            <path
              className="cls-6"
              d="M947.68,482.56h-53.3v386.43l179.72-314.41c-25.62-43.09-72.7-72.02-126.42-72.02Z"
            />
            <path
              className="cls-1"
              d="M894.38,1043.92h53.3c80.95,0,146.89-65.64,146.89-146.59v-57.99l-200.19,135.98v68.6Z"
            />
            <path
              className="cls-2"
              d="M1074.1,554.58l-179.72,314.41v106.33l200.19-135.98v-210.21c0-27.21-7.49-52.7-20.47-74.54Z"
            />
            <polygon
              className="cls-3"
              points="759.86 83.49 703.15 0 683.29 0 587.27 141.36 587.27 636.55 759.86 519.32 759.86 83.49"
            />
            <polygon
              className="cls-4"
              points="703.15 0 759.86 83.49 759.86 0 703.15 0"
            />
            <polygon
              className="cls-5"
              points="587.27 1017.15 542.47 1017.15 542.47 1043.92 759.86 1043.92 759.86 1017.75 587.27 715.79 587.27 1017.15"
            />
            <polygon
              className="cls-6"
              points="587.27 636.55 587.27 715.79 759.86 1017.75 759.86 519.32 587.27 636.55"
            />
            <polygon
              className="cls-1"
              points="542.47 0 542.47 26.77 587.27 26.77 587.27 141.36 683.29 0 542.47 0"
            />
            <path
              className="cls-5"
              d="M1082.39,146.57C1082.39,65.63,1016.46,0,935.52,0h-41.14V76.77l188.01,127.72v-57.91Z"
            />
            <polygon
              className="cls-4"
              points="962.44 381.71 1082.39 300.24 1082.39 204.49 894.38 76.77 894.38 281.53 962.44 381.71"
            />
            <path
              className="cls-5"
              d="M1001.27,438.88c48.04-24.09,81.12-73.7,81.12-131.03v-7.61l-119.95,81.48,38.83,57.17Z"
            />
            <path
              className="cls-6"
              d="M894.38,427.93v26.51h41.14c23.62,0,45.95-5.63,65.75-15.56l-38.83-57.17-68.06,46.22Z"
            />
            <polygon
              className="cls-3"
              points="894.38 281.53 894.38 427.93 962.44 381.71 894.38 281.53"
            />
            <path
              className="cls-6"
              d="M947.68,482.56h-53.3v386.43l179.72-314.41c-25.62-43.09-72.7-72.02-126.42-72.02Z"
            />
            <path
              className="cls-1"
              d="M894.38,1043.92h53.3c80.95,0,146.89-65.64,146.89-146.59v-57.99l-200.19,135.98v68.6Z"
            />
            <path
              className="cls-2"
              d="M1074.1,554.58l-179.72,314.41v106.33l200.19-135.98v-210.21c0-27.21-7.49-52.7-20.47-74.54Z"
            />
          </g>
        </g>
      </g>
    </svg>
  );
};

export default Icon;
