import React from "react";

const MemberFrame = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={150}
      height={218}
      viewBox="0 0 150 218"
      fill="none"
    >
      <g clipPath="url(#clip0_1_374)">
        <path
          d="M33.6249 84.2983L60.0175 89.0882L61.2953 137.312L16.4477 128.106L33.6249 84.2983Z"
          fill="#23AAA8"
        />
        <path
          d="M11.631 122.944L33.6248 84.2983L16.4476 128.106L14.438 124.375L11.631 122.944Z"
          fill="#1F68B5"
        />
        <path
          d="M33.4684 59.2901L32.7609 59.9229C32.5953 60.0804 32.3744 60.1657 32.1464 60.16C31.9185 60.1543 31.702 60.0581 31.5444 59.8926L19.0341 46.0741C18.888 45.8998 18.8161 45.6748 18.8338 45.4477C18.8516 45.2206 18.9576 45.0096 19.129 44.8604L19.8321 44.2276C19.9142 44.149 20.011 44.0873 20.1168 44.0463C20.2226 44.0052 20.3354 43.9856 20.4488 43.9884C20.5622 43.9912 20.6739 44.0165 20.7776 44.0627C20.8813 44.109 20.9748 44.1753 21.053 44.2579L33.5633 58.0764C33.7094 58.2507 33.7813 58.4757 33.7636 58.7028C33.7458 58.9299 33.6398 59.1409 33.4684 59.2901Z"
          fill="black"
        />
        <path
          d="M70.3921 49.8534C68.945 48.6803 57.7637 53.5774 57.9054 55.2867C58.0472 56.996 50.1032 73.5343 50.1032 73.5343L36.3746 62.6044C36.2515 62.2096 34.9499 57.9568 34.8827 57.1971C34.8156 56.4374 32.4996 55.0037 31.4217 55.2085C30.3439 55.4133 32.3429 56.7651 32.4734 57.0146C32.8464 57.6887 31.4478 57.9419 31.4478 57.9419C31.4478 57.9419 27.4833 55.7671 27.3975 54.4897C27.3117 53.2124 26.4949 50.5311 25.6371 51.0897C25.197 51.3802 25.2008 53.2794 25.3388 54.1844C25.0852 53.1603 24.7234 52.0021 24.3728 51.8866C23.7313 51.6781 23.4106 52.4936 23.5262 52.8958C23.6418 53.298 24.6786 57.6403 24.9956 58.1318C25.3126 58.6234 31.7089 63.7625 32.6301 64.8797C32.6301 64.8797 50.3866 85.1495 51.6509 85.6783C52.7698 86.1438 56.7157 79.1613 60.4528 74.5063C64.1898 69.8513 74.9086 53.5215 70.3921 49.8534Z"
          fill="#F7A491"
        />
        <path
          d="M106.222 198.892C106.222 198.892 108.661 201.268 108.717 202.143C108.773 203.018 108.934 212.362 107.938 213.233C106.942 214.105 104.014 214.723 104.954 215.706C105.666 216.451 111.134 216.291 114.759 216.078C115.818 216.023 117.146 207.591 117.564 206.351C118.172 204.553 119.652 200.501 119.1 199.246C118.832 198.635 117.541 197.384 115.721 196.207C113.812 194.971 111.992 192.226 111.992 192.226L106.222 198.892Z"
          fill="#F48C7F"
        />
        <path
          d="M80.9431 204.437C80.9431 204.437 83.1286 210.597 83.1286 210.612C83.2927 211.587 83.9305 213.997 83.9305 214.887C83.9305 218.216 68.8816 212.056 72.2532 210.578C72.9986 210.01 73.4891 209.171 73.6182 208.243C73.7711 206.497 72.3501 204.303 72.3501 204.303L80.9431 204.437Z"
          fill="#F7A491"
        />
        <path
          d="M57.3311 89.7375C57.3311 89.7375 56.2346 100.779 56.2346 100.943C56.2346 118.818 52.6878 153.694 53.3628 157.053C55.0076 165.246 72.7156 207.029 72.7156 207.029L82.282 205.886L69.814 155.857L74.4014 135.722L80.8946 95.9194L57.3311 89.7375Z"
          fill="black"
        />
        <path
          d="M89.4503 99.4982L91.2666 109.966L96.1784 153.046L114.886 195.127C114.886 195.127 112.275 201.253 108.374 202.113L78.5934 158.915L60.0984 103.609L60.7474 91.3203L89.4503 99.4982Z"
          fill="#232323"
        />
        <path
          d="M53.5157 63.4423L66.7856 64.7866C66.7856 64.7866 67.3935 53.9237 67.3935 48.7436C67.3935 42.4575 66.8564 34.3615 66.0956 34.5328C63.9623 35.0243 61.0905 36.2011 59.3824 38.5472C53.4561 46.6768 53.5157 63.4423 53.5157 63.4423Z"
          fill="#D8D8D8"
        />
        <path
          d="M55.9385 91.2337C56.0328 91.2264 56.1246 91.2005 56.2088 91.1574C56.2929 91.1143 56.3675 91.055 56.4284 90.9827C56.4892 90.9105 56.5351 90.827 56.5632 90.7369C56.5914 90.6468 56.6012 90.5519 56.5923 90.4579C56.3064 87.5596 55.8671 84.6782 55.2761 81.8259C53.8521 75.2032 51.9679 71.7468 49.4991 71.2706C47.0303 70.7944 43.9661 73.2587 40.1197 78.8147C38.487 81.2127 36.981 83.6943 35.608 86.2495C35.563 86.3329 35.5352 86.4245 35.5261 86.5188C35.5171 86.6132 35.5271 86.7083 35.5554 86.7988C35.5837 86.8893 35.6299 86.9732 35.6911 87.0457C35.7524 87.1181 35.8276 87.1777 35.9122 87.2207C36.079 87.3057 36.2726 87.3217 36.451 87.2653C36.6295 87.209 36.7785 87.0847 36.8657 86.9195C40.0825 80.828 45.9886 72.0459 49.2288 72.6717C52.4691 73.2975 54.5627 83.6985 55.1744 90.588C55.1818 90.6806 55.2075 90.7708 55.25 90.8534C55.2925 90.936 55.351 91.0094 55.422 91.0694C55.493 91.1294 55.5752 91.1748 55.6638 91.203C55.7525 91.2312 55.8458 91.2416 55.9385 91.2337Z"
          fill="#21BAB7"
        />
        <path
          d="M102.59 115.616C104.611 112.346 105.066 112.812 105.316 112.387C105.611 111.881 105.282 104.097 105.282 104.097C105.282 104.097 104.469 81.1537 104.384 79.9136L94.019 58.7314L102.97 50.4157C102.97 50.4157 113.64 73.7727 113.987 75.0947C114.334 76.4168 111.865 107.602 111.749 110.354C111.749 110.354 112.786 113.683 113.372 115.794C113.957 117.906 113.581 120.181 113.655 120.803C113.726 122.41 113.601 124.019 113.282 125.596C112.838 127.83 112.245 125.514 112.245 125.514C112.245 125.514 112.331 121.194 111.996 121.25C111.735 121.295 111.824 123.656 111.358 126.155C111.235 126.795 111.115 127.432 110.985 128.017C110.679 129.544 109.672 129.797 109.739 128.154C109.762 127.405 109.838 126.659 109.967 125.92C110.053 125.224 110.085 124.521 110.06 123.82C110.134 123.101 110.16 122.378 110.138 121.656C110.071 121.69 109.911 121.433 109.765 122.11C109.586 122.926 109.583 124.74 109.232 125.92C109.031 126.587 108.695 128.009 108.695 128.009C108.695 128.009 107.789 130.069 107.654 130.057C106.908 129.994 107.147 128.415 107.535 127.54C107.583 126.807 107.708 126.08 107.908 125.373C107.908 125.373 108.046 122.189 107.602 122.166C107.412 122.166 107.099 124.103 106.483 125.965C105.924 127.689 105.081 129.346 104.73 129.29C104.13 129.193 104.551 127.212 104.551 127.212C104.551 127.212 104.924 125.838 105.006 125.373C105.107 124.825 105.29 123.172 105.29 123.172C105.29 123.172 105.663 117.377 105.245 117.392C104.827 117.407 103.753 117.887 103.608 118.241C103.462 118.595 103.526 119.358 103.38 119.731C103.293 119.932 103.138 120.096 102.943 120.196C102.748 120.296 102.524 120.325 102.31 120.278C101.87 120.133 101.937 118.163 101.937 118.163C101.937 118.163 102.004 116.55 102.59 115.616Z"
          fill="#F8A492"
        />
        <path
          d="M60.035 47.6562C59.7553 49.5741 54.6533 101.68 54.6533 101.68C78.8172 113.333 92.1505 103.758 92.1505 103.758C92.1505 103.758 91.3598 67.1663 92.3071 62.1873C92.9337 58.8915 93.7132 36.9943 91.5612 36.3836C88.5188 35.4469 85.3983 34.7851 82.2373 34.4061C79.3095 34.1008 67.938 33.9258 65.9017 34.6073C62.7567 38.3199 60.7234 42.8424 60.035 47.6562Z"
          fill="#E8E8E8"
        />
        <path
          d="M108.825 58.4745L97.3271 66.1832C97.3271 66.1832 90.8488 56.5528 87.3691 53.1268C83.013 48.833 88.958 35.6798 91.7663 36.4246C98.0432 38.0892 108.825 58.4745 108.825 58.4745Z"
          fill="#E8E8E8"
        />
        <path
          d="M115.606 196.159C115.606 196.159 109.982 211.967 107.774 212.731C107.188 212.935 104.383 213.449 103.854 214.686C103.694 215.058 103.854 216.306 103.854 216.306H115.583C115.583 216.306 117.075 209.338 117.653 206.281C117.653 206.281 120.753 198.799 120.41 198.121C120.066 197.443 115.606 196.159 115.606 196.159Z"
          fill="#565656"
        />
        <path
          d="M84.7883 215.982L83.0652 210.545C83.0652 210.545 79.4438 212.071 77.635 211.818C75.8332 211.483 74.0494 211.058 72.2905 210.545C65.0663 212.917 59.1474 214.071 60.2253 215.982H84.7883Z"
          fill="#565656"
        />
        <path
          d="M79.0447 23.6922L80.82 34.5439C80.82 34.5439 74.3156 48.3526 70.4257 47.2056C67.1623 46.2299 69.1725 34.3727 69.1725 34.3727C69.1725 34.3727 70.9478 28.0046 70.4145 24.8168C69.8812 21.6291 79.0447 23.6922 79.0447 23.6922Z"
          fill="#F7A491"
        />
        <path
          d="M70.4145 24.7982C69.8812 21.6104 79.0447 23.681 79.0447 23.681L79.1939 24.612C78.9595 25.1915 78.6222 25.7239 78.1981 26.1835C76.0424 28.2793 73.1925 29.514 70.187 29.6543C70.463 27.945 70.642 26.1053 70.4145 24.7982Z"
          fill="#D58979"
        />
        <path
          d="M80.5477 7.76457C80.8051 7.6752 82.6214 20.5863 79.1753 24.1502C75.7291 27.7141 69.0122 28.8648 66.6028 26.0681C63.9362 22.9734 64.0705 9.09405 69.4299 5.44079C74.7893 1.78753 80.5477 7.76457 80.5477 7.76457Z"
          fill="#F7A491"
        />
        <path
          d="M79.2834 2.78929C81.1482 3.00155 83.7216 3.99959 86.3696 8.18538C89.6144 13.332 87.2163 19.0334 83.4382 21.8339C79.6601 24.6343 79.7273 27.8333 79.7273 27.8333L79.1156 26.191C78.6657 24.9306 78.5039 23.5856 78.642 22.2547C78.713 21.4368 78.9197 20.6364 79.2536 19.8862C80.1636 18.0242 77.2993 16.7767 76.4974 15.22C75.8821 14.0246 75.8522 11.2838 74.8788 9.30631L73.7152 7.8167C73.7152 7.8167 71.015 5.79828 68.4043 6.3606C65.7935 6.92293 69.001 -5.33279 79.2834 2.78929Z"
          fill="#8D645B"
        />
        <path
          d="M76.6653 17.5178C76.6392 19.093 77.3888 20.3852 78.3361 20.4001C79.2834 20.415 80.0778 19.1489 80.1077 17.5736C80.1375 15.9984 79.3879 14.7099 78.4368 14.695C77.4858 14.6801 76.6951 15.9425 76.6653 17.5178Z"
          fill="#F7A491"
        />
        <path
          d="M51.651 85.6709C51.5202 85.5908 51.3955 85.5012 51.278 85.4028C51.7069 80.2971 51.6286 75.3479 50.0249 73.6796C50.0622 73.5977 50.9052 71.786 51.2781 71.0151C53.1429 72.4526 56.7004 73.9054 56.3536 80.0985C56.2999 80.5304 53.033 84.3858 52.9227 84.8069C52.4043 85.3991 51.9158 85.7826 51.651 85.6709Z"
          fill="#F7A491"
        />
        <path
          d="M100.423 40.2082C100.214 35.3036 100.956 30.4587 103.212 25.8931C105.152 21.9605 109.956 19.7261 114.334 19.7261C128.242 19.7261 129.998 37.6014 138.863 44.4796C141.515 46.5464 144.477 48.2371 147.005 50.438C147.045 51.8991 146.819 53.3553 146.338 54.7355C143.608 61.9676 136.641 66.0603 129.927 69.7433C123.874 72.987 117.433 74.3723 110.869 74.6739C108.426 69.7396 106.703 64.2727 104.824 59.1783C102.59 53.0932 100.702 46.7364 100.423 40.2082Z"
          fill="#2F4566"
        />
        <path
          d="M77.0308 117.373C77.0308 117.373 78.0713 116.584 78.3995 116.297C78.7277 116.01 79.8391 114.856 79.8391 114.856C79.8391 114.856 83.3524 110.845 83.0242 110.614C82.696 110.383 81.6965 110.737 81.3906 110.909C81.0848 111.08 80.7081 111.255 80.3986 111.426C80.237 111.512 80.0486 111.531 79.8728 111.482C79.6969 111.432 79.5472 111.316 79.455 111.158C79.2051 110.786 80.2718 109.851 80.7081 109.594C82.0657 108.801 86.1459 107.393 86.571 107.229C87.0745 107.032 91.8372 98.9694 93.6983 96.3328C96.6073 91.9347 100.978 86.1625 101.284 85.6933L105.995 65.1851L115.043 64.4403C115.043 64.4403 108.382 88.5272 107.904 89.6966C107.68 90.2515 100.121 99.9302 100.117 99.9264C96.0815 104.459 92.1766 108.443 91.5127 109.236C91.5127 109.236 90.5244 112.115 89.7897 113.996C89.0549 115.876 87.6041 117.31 87.3132 117.805C86.5909 119.108 85.6454 120.274 84.5198 121.25C82.9272 122.639 83.7738 120.591 83.7738 120.591C83.7738 120.591 86.2465 117.489 85.9594 117.332C85.7393 117.213 84.4937 118.99 82.7482 120.543L81.4429 121.69C80.3613 122.628 79.4587 122.23 80.4247 121.068C80.8598 120.538 81.3321 120.04 81.8382 119.578C82.2917 119.117 82.7059 118.619 83.0764 118.088C83.5321 117.605 83.9556 117.093 84.3445 116.554C84.2736 116.554 84.296 116.256 83.8037 116.666C83.2144 117.157 82.2037 118.479 81.2788 119.135C80.7566 119.507 79.7086 120.353 79.7086 120.353C79.7086 120.353 77.8438 121.533 77.7618 121.448C77.2434 120.975 78.3137 119.749 79.0969 119.336C79.5437 118.827 80.0438 118.368 80.5888 117.966C80.5888 117.966 82.4536 115.731 82.1403 115.448C81.8755 115.221 76.3147 119.723 75.8261 119.213C75.4233 118.803 77.0308 117.373 77.0308 117.373Z"
          fill="#F48C7F"
        />
        <path
          d="M143.895 198.129C143.895 198.129 144.872 204.728 146.558 206.858C147.124 207.573 148.158 208.474 148.836 209.766C149.892 211.777 149.306 214.771 148.531 216.421C148.128 217.266 148.557 217.263 147.647 217.538C146.346 217.918 144.953 217.818 143.72 217.257C142.488 216.696 141.498 215.71 140.933 214.481C140.14 212.707 139.693 210.799 139.617 208.858C139.33 202.866 137.413 199.823 137.413 199.823L143.895 198.129Z"
          fill="#F7A491"
        />
        <path
          d="M114.405 201.149C114.405 201.149 113.801 208.597 114.293 211.006C114.554 212.299 116.02 214.965 115.348 216.183C114.677 217.4 113.909 217.706 113.909 217.706L96.4992 217.978C95.5519 217.978 96.1784 217.4 96.943 216.86C98.1961 215.967 99.2068 215.371 103.201 213.509C103.462 213.386 104.413 212.801 104.753 212.6C105.677 212.005 106.516 211.286 107.244 210.463C108.494 209.111 108.602 202.56 108.602 202.56L114.405 201.149Z"
          fill="#F7A491"
        />
        <path
          d="M100.799 102.254C100.799 102.254 98.6101 114.335 100.053 122.252C101.497 130.169 108.404 203.577 108.404 203.577L116.079 203.998C116.079 203.998 117.687 173.334 117.4 169.07C117.113 164.806 114.834 157.671 114.834 157.671L115.58 132.914L105.883 100.392L100.799 102.254Z"
          fill="#DBDBDB"
        />
        <path
          d="M123.363 99.7142C123.363 99.7142 129.405 108.548 130.845 115.858C132.284 123.168 129.789 145.631 132.557 155.213C134.049 160.397 139.736 168.407 140.601 175.107C141.467 181.806 144.861 199.361 144.861 199.361L137.189 200.851L119.358 157.016L105.368 117.392L101.788 96.9882L123.363 99.7142Z"
          fill="#EEEEEE"
        />
        <path
          d="M118.47 49.4586C118.47 49.4586 126.47 48.9633 129.636 52.3112C132.803 55.6591 122.614 82.1666 122.039 86.4306C121.465 90.6946 124.975 102.034 124.975 102.034C124.975 102.034 106.174 105.706 100.799 102.254C100.799 102.254 99.8148 71.2963 100.389 68.25C100.963 65.2038 106.174 53.1119 107.326 51.8941C108.479 50.6764 118.47 49.4586 118.47 49.4586Z"
          fill="black"
        />
        <path
          d="M115.803 39.091C115.803 39.091 115.845 39.3181 115.919 39.7092C116.292 41.5712 117.65 47.7717 118.578 49.4773C119.697 51.5366 114.741 58.1021 109.963 57.2977C108.542 57.0593 111.164 51.2648 111.037 50.6503C110.798 49.4735 110.47 47.8722 110.135 46.2262C109.944 45.2989 109.762 44.3642 109.575 43.4779L108.646 38.9122L115.803 39.091Z"
          fill="#F7A491"
        />
        <path
          d="M115.919 39.6942C115.845 39.3218 115.803 39.0761 115.803 39.0761L108.646 38.901L109.575 43.4667L110.131 46.2001C111.406 45.984 112.593 45.4076 113.551 44.5392C114.726 43.5459 115.618 42.2616 116.139 40.8152C116.05 40.3608 115.975 39.981 115.919 39.6942Z"
          fill="#D58979"
        />
        <path
          d="M110.989 23.2788C110.989 23.2788 114.345 22.9064 116.475 25.5579C118.604 28.2094 120.868 36.4246 115.617 40.8859C110.366 45.3473 105.174 40.2976 104.406 34.9275C103.638 29.5575 103.335 23.0777 110.989 23.2788Z"
          fill="#F7A491"
        />
        <path
          d="M102.851 34.8605C103.477 36.0447 104.529 36.7001 105.2 36.324C105.872 35.9479 105.909 34.6855 105.282 33.5049C104.656 32.3244 103.6 31.6653 102.929 32.0414C102.258 32.4175 102.224 33.68 102.851 34.8605Z"
          fill="#F7A491"
        />
        <path
          d="M110.612 22.4037C110.612 22.4037 115.117 30.2241 118.34 30.5704C121.562 30.9168 121.987 35.0802 121.987 35.0802C121.987 35.0802 120.835 25.5207 117.642 23.6773C114.45 21.8339 110.612 22.4037 110.612 22.4037Z"
          fill="#2F4566"
        />
        <path
          d="M140.411 213.159C144.596 213.904 147.378 211.524 148.825 209.744C149.888 211.759 150.407 214.764 149.623 216.421C148.937 217.877 146.923 217.836 145.338 217.911C143.663 217.97 142.354 217.069 141.538 215.65C141.077 214.861 140.7 214.026 140.411 213.159Z"
          fill="#35130D"
        />
        <path
          d="M104.756 212.582C109.359 213.729 112.764 212.954 114.666 212.209C115.158 213.557 115.807 215.252 115.464 216.231C115.039 217.427 114.438 217.698 113.909 217.721C109.702 217.829 98.5057 217.974 95.2982 218.015C95.1998 218.019 95.1023 217.994 95.0177 217.943C94.9331 217.893 94.8651 217.819 94.822 217.73C94.7789 217.642 94.7625 217.543 94.7749 217.445C94.7873 217.348 94.8279 217.256 94.8917 217.181C95.6861 216.242 104.413 212.786 104.756 212.582Z"
          fill="#35130D"
        />
        <path
          d="M117.497 32.533C117.232 33.9221 117.761 35.1919 118.675 35.3744C119.589 35.5569 120.54 34.5812 120.801 33.1921C121.062 31.8031 120.536 30.5332 119.623 30.3544C118.709 30.1757 117.758 31.1477 117.497 32.533Z"
          fill="#F7A491"
        />
        <path
          d="M136.525 102.027C134.655 99.462 132.557 97.0713 130.256 94.8841C127.03 91.9272 123.915 90.2738 119.6 91.2048C115.654 92.1469 113.573 94.1542 111.914 95.3682C111.914 95.3682 108.285 98.4778 105.965 101.677L108.576 103.166C109.195 101.718 111.339 98.4703 113.842 96.1615C116.885 93.32 119.559 92.7242 119.548 92.7279C120.498 92.4663 121.483 92.352 122.468 92.389C122.863 92.4139 123.256 92.4687 123.643 92.5529C128.268 93.6254 132.766 100.466 133.851 103.431L136.525 102.027Z"
          fill="#A4160F"
        />
        <path
          d="M129.569 78.2601L133.534 91.9719C128.398 91.633 116.173 92.9141 113.085 93.0556C108.355 92.4784 106.894 92.8843 105.745 93.4653C105.126 93.7781 102.612 95.223 102.612 95.223C102.612 95.223 100.646 98.0421 100.844 98.1799C101.504 98.6528 103.291 95.9455 103.291 95.9455C103.291 95.9455 104.999 95.1411 105.267 95.0554C105.924 94.8506 105.663 95.1411 105.663 95.1411C105.133 95.3943 102.989 96.8951 102.989 96.8951C102.989 96.8951 99.9826 99.8259 100.221 99.8966C100.363 99.9376 101.068 99.6732 102.168 98.8874C102.839 98.407 103.813 97.4611 104.447 97.0738C105.566 96.396 106.412 96.329 106.043 96.6158C105.775 96.8318 105.267 96.8541 104.6 97.2563C102.974 98.2431 100.926 100.187 101.269 100.31C101.612 100.433 103.268 99.2338 104.853 98.448C105.543 98.1091 107.013 98.0086 107.938 97.9415C107.777 98.1943 107.586 98.4257 107.367 98.6305C107.125 98.8576 104.991 99.8296 105.349 100.291C105.707 100.753 110.593 98.7832 110.593 98.7832L112.939 97.3792L113.088 97.3568C113.088 97.3568 136.797 102.6 141.69 97.2451C145.86 92.6795 137.89 73.3035 137.842 73.4115L129.569 78.2601Z"
          fill="#F8A492"
        />
        <path
          d="M136.525 102.027C134.655 99.462 132.557 97.0713 130.256 94.8841C127.03 91.9272 123.915 90.2738 119.6 91.2048C115.654 92.1469 119.324 92.3927 119.574 92.5566C121.305 92.4225 122.487 92.4039 122.476 92.4039C122.871 92.4288 123.264 92.4836 123.651 92.5678C128.275 93.6403 132.773 100.481 133.858 103.446L136.525 102.027Z"
          fill="#21BAB7"
        />
        <path
          d="M129.293 80.0551C129.293 80.0551 120.253 66.5444 119.44 61.5803C118.627 56.6162 123.099 50.4716 127.645 50.9482C132.191 51.4249 138.491 74.0371 138.491 74.0371L129.293 80.0551Z"
          fill="black"
        />
        <path
          d="M108.404 126.777H135.63C136.33 126.784 137.024 126.64 137.663 126.354C138.302 126.068 138.871 125.647 139.332 125.121C139.793 124.594 140.134 123.974 140.332 123.303C140.53 122.633 140.58 121.927 140.478 121.235L136.547 100.869H106.11L103.5 121.537C103.444 122.21 103.53 122.887 103.752 123.526C103.974 124.164 104.327 124.748 104.789 125.242C105.251 125.735 105.811 126.127 106.434 126.391C107.057 126.655 107.728 126.787 108.404 126.777Z"
          fill="#21BAB7"
        />
        <path
          d="M103.5 121.537L106.11 100.869C108.203 100.779 110.321 100.634 112.272 100.869L109.661 121.537C109.605 122.21 109.691 122.887 109.913 123.525C110.135 124.164 110.489 124.748 110.951 125.242C111.413 125.735 111.973 126.127 112.595 126.391C113.218 126.655 113.889 126.787 114.565 126.777H108.404C107.728 126.787 107.057 126.655 106.434 126.391C105.811 126.127 105.251 125.735 104.789 125.242C104.327 124.748 103.974 124.164 103.752 123.525C103.53 122.887 103.444 122.21 103.5 121.537Z"
          fill="#1F68B5"
        />
      </g>
      <defs>
        <clipPath id="clip0_1_374">
          <rect
            width={150}
            height={218}
            fill="white"
            transform="matrix(-1 0 0 1 150 0)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default MemberFrame;
