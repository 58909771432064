import axios from "axios";
import qs from "qs";

const API_ROUTES = {
  BONUSES_LIST: "bonus/list/",
  BONUS: "bonus/get/",
  BONUS_REQUEST: "bonus/request/",
  BONUS_ACTIVATE: "bonus/activate/",
  BONUS_CATEGORIES: "bonus/category/list/",

  REGISTRATION: "user/registration/",
  LOGIN: "user/login/",
  USER: "user/account/",
  USER_EDIT: "user/account/update/",

  TRANSACTIONS: "transaction/list/",
  TRANSACTION_BONUSES: "transaction/bonus/list/",
  TRANSACTION_BONUS: "transaction/bonus/get/",

  NEWS_LIST: "news/list/",
  NEWS_ITEM: "news/get/",

  CONTACT: "request/send/",
  CONTACT_TYPES: "request/type/list/",

  SEND_INVITE: "friend/invite/send/",
  CHECK_INVITE: "friend/invite/check/",

  SERVICE: "service/data/",

  ROULETE: "bonus/luckybutton/activate/",
};

export const api_key = "TwKAH_$QNWA1";

const defaultOptions = {
  api_key,
  mall_id: 1,
};

const baseURL = "https://api-vyhodnoi.jetcrm.ru/";

const api = axios.create({
  baseURL,
  headers: {
    "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
  },
});

export const BonusesApi = {
  async getBonusesList(dto) {
    const { data } = await api.post(
      API_ROUTES.BONUSES_LIST,
      qs.stringify({ ...defaultOptions, limit: 1000, ...dto })
    );

    return data;
  },

  async getBonus(dto) {
    const { data } = await api.post(
      API_ROUTES.BONUS,
      qs.stringify({ ...defaultOptions, ...dto })
    );

    return data;
  },

  async bonusRequest(dto) {
    const { data } = await api.post(
      API_ROUTES.BONUS_REQUEST,
      qs.stringify({ ...defaultOptions, ...dto })
    );

    return data;
  },

  async bonusActivate(dto) {
    const { data } = await api.post(
      API_ROUTES.BONUS_ACTIVATE,
      qs.stringify({ ...defaultOptions, ...dto })
    );

    return data;
  },

  async getBonusesCategories(dto) {
    const { data } = await api.post(
      API_ROUTES.BONUS_CATEGORIES,
      qs.stringify({ ...defaultOptions, ...dto })
    );

    return data;
  },
};

export const UserApi = {
  async register(dto) {
    const { data } = await api.post(
      API_ROUTES.REGISTRATION,
      qs.stringify({ ...defaultOptions, ...dto })
    );

    return data;
  },

  async login(dto) {
    const { data } = await api.post(
      API_ROUTES.LOGIN,
      qs.stringify({ ...defaultOptions, ...dto })
    );

    return data;
  },

  async getData(dto) {
    const { data } = await api.post(
      API_ROUTES.USER,
      qs.stringify({ ...defaultOptions, ...dto })
    );

    return data;
  },

  async edit(dto) {
    const { data } = await api.post(API_ROUTES.USER_EDIT, dto, {
      baseURL,
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });

    return data;
  },
};

export const TransactionApi = {
  async getTransactions(dto) {
    const { data } = await api.post(
      API_ROUTES.TRANSACTIONS,
      qs.stringify({ ...defaultOptions, ...dto })
    );

    return data;
  },

  async getTransactionBonuses(dto) {
    const { data } = await api.post(
      API_ROUTES.TRANSACTION_BONUSES,
      qs.stringify({ ...defaultOptions, activated: 2, ...dto })
    );

    return data;
  },

  async getTransactionBonus(dto) {
    const { data } = await api.post(
      API_ROUTES.TRANSACTION_BONUS,
      qs.stringify({ ...defaultOptions, ...dto })
    );

    return data;
  },
};

export const NewsApi = {
  async getNewsList(dto) {
    const { data } = await api.post(
      API_ROUTES.NEWS_LIST,
      qs.stringify({ ...defaultOptions, ...dto })
    );

    return data;
  },

  async getNewsItem(dto) {
    const { data } = await api.post(
      API_ROUTES.NEWS_ITEM,
      qs.stringify({ ...defaultOptions, ...dto })
    );

    return data;
  },
};

export const ContactApi = {
  async getMessageTypes(dto) {
    const { data } = await api.post(
      API_ROUTES.CONTACT_TYPES,
      qs.stringify({ ...defaultOptions, ...dto })
    );

    return data;
  },

  async sendMessage(dto) {
    const { data } = await api.post(API_ROUTES.CONTACT, dto, {
      baseURL,
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });

    return data;
  },
};

export const InviteFriendApi = {
  async sendInvite(dto) {
    const { data } = await api.post(
      API_ROUTES.SEND_INVITE,
      qs.stringify({ ...defaultOptions, ...dto })
    );

    return data;
  },

  async checkInvite(dto) {
    const { data } = await api.post(
      API_ROUTES.CHECK_INVITE,
      qs.stringify({ ...defaultOptions, ...dto })
    );

    return data;
  },
};

export const ServiceApi = {
  async getData(dto) {
    const { data } = await api.post(
      API_ROUTES.SERVICE,
      qs.stringify({ ...defaultOptions, ...dto })
    );

    return data;
  },
};

export const SpinApi = {
  async spin(dto) {
    const { data } = await api.post(
      API_ROUTES.ROULETE,
      qs.stringify({ ...defaultOptions, ...dto })
    );

    return data;
  },
};
