import React from "react";
import Logo from "icons/Logo";
import Frame from "icons/Frame";
import PrimaryButton from "./buttons/PrimaryButton";

const DesktopScreen = ({ toggleVisibility }) => {
  return (
    <section className="desktop">
      <div className="desktop__frame">
        <Frame />
      </div>

      <div className="desktop__container">
        <div className="desktop__content">
          <div className="desktop__logo">
            <Logo />
          </div>

          <h1 className="desktop__title">Уважаемые посетители!</h1>

          <p className="desktop__text">
            Сайт программы лояльности ТРЦ Выходной адаптирован для мобильных
            устройств. Информируем Вас, что для ПК работает в режиме
            ограниченной функциональности. 
          </p>

          <PrimaryButton
            text={"Перейти на сайт"}
            long
            callback={toggleVisibility}
          />
        </div>
      </div>
    </section>
  );
};

export default DesktopScreen;
