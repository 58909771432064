import React from "react";

const Frame = () => {
  return (
    <svg
      width={326}
      height={167}
      viewBox="0 0 326 167"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_1_417)">
        <path
          d="M349.714 41.8294H301.471L325.592 0L349.714 41.8294Z"
          fill="#F58220"
        />
        <path
          d="M244.241 119.118H213.967L229.1 92.8601L244.241 119.118Z"
          fill="#9E75B2"
        />
        <path
          d="M301.471 41.8294H253.229L277.35 0L301.471 41.8294Z"
          fill="#C2D6D6"
        />
        <path
          d="M253.229 41.8294H204.979L229.1 0L253.229 41.8294Z"
          fill="#EE3724"
        />
        <path
          d="M325.592 83.6667L301.471 41.8295H349.714L325.592 83.6667Z"
          fill="#9E75B2"
        />
        <path
          d="M24.121 41.8294L-6.86646e-05 0H48.2422L24.121 41.8294Z"
          fill="#90150F"
        />
        <path
          d="M325.593 83.6667L349.714 41.8295L373.843 83.6667H325.593Z"
          fill="#EF3E26"
        />
        <path
          d="M325.593 0H373.843L349.714 41.8294L325.593 0Z"
          fill="#EE3724"
        />
        <path d="M277.35 0H325.593L301.471 41.8294L277.35 0Z" fill="#F58220" />
        <path d="M229.1 0H277.35L253.229 41.8294L229.1 0Z" fill="#F58220" />
        <path
          d="M204.979 41.8295H253.229L229.1 83.6667L204.979 41.8295Z"
          fill="#FFEE00"
        />
        <path
          d="M48.2422 0H96.4922L72.3711 41.8294L48.2422 0Z"
          fill="#EE3724"
        />
        <path
          d="M156.737 41.8294L132.608 0H180.858L156.737 41.8294Z"
          fill="#C2D6D6"
        />
        <path
          d="M156.737 41.8294L180.858 0L204.979 41.8294H156.737Z"
          fill="#EE3724"
        />
        <path
          d="M31.944 36.8361L48.2423 8.56348L64.5483 36.8361H31.944Z"
          fill="#EE3724"
        />
        <path
          d="M108.487 41.8294L132.608 0L156.737 41.8294H108.487Z"
          fill="#F58220"
        />
        <path d="M229.1 0L204.979 41.8294L180.858 0H229.1Z" fill="#EE3724" />
        <path
          d="M325.593 167.333H277.35L301.471 125.496L325.593 167.333Z"
          fill="#FFEE00"
        />
        <path
          d="M349.714 125.496H301.471L325.592 83.6667L349.714 125.496Z"
          fill="#FFEE00"
        />
        <path
          d="M268.906 166.392L249.174 132.17H288.638L268.906 166.392Z"
          fill="#9E75B2"
        />
        <path
          d="M325.593 167.333L349.714 125.496L373.843 167.333H325.593Z"
          fill="#EE3724"
        />
        <path
          d="M373.843 83.6667L349.714 125.496L325.593 83.6667H373.843Z"
          fill="#CE3120"
        />
        <path
          d="M301.471 125.496H349.714L325.592 167.333L301.471 125.496Z"
          fill="#FFEE00"
        />
        <path
          d="M277.35 83.6667H229.1L253.229 41.8295L277.35 83.6667Z"
          fill="#6CCBD6"
        />
        <path
          d="M253.229 125.496L229.1 83.6667H277.35L253.229 125.496Z"
          fill="#6CCBD6"
        />
        <path
          d="M277.35 83.6667L301.471 41.8295L325.593 83.6667H277.35Z"
          fill="#1F68B5"
        />
        <path
          d="M253.229 125.496L277.35 83.6667L301.471 125.496H253.229Z"
          fill="#21BAB7"
        />
        <path
          d="M325.593 83.6667L301.471 125.496L277.35 83.6667H325.593Z"
          fill="#21BAB7"
        />
        <path
          d="M253.229 41.8295H301.471L277.35 83.6667L253.229 41.8295Z"
          fill="#1F68B5"
        />
        <path
          d="M180.858 83.6667H132.608L156.737 41.8295L180.858 83.6667Z"
          fill="#1F68B5"
        />
        <path
          d="M156.737 125.496L132.608 83.6667H180.858L156.737 125.496Z"
          fill="#1F68B5"
        />
        <path
          d="M180.858 83.6667L204.979 41.8295L229.1 83.6667H180.858Z"
          fill="#21BAB7"
        />
        <path
          d="M72.3712 41.8295L90.4329 73.151L108.487 41.8295H72.3712Z"
          fill="#6CCBD6"
        />
        <path
          d="M156.737 125.496L180.858 83.6667L204.979 125.496H156.737Z"
          fill="#6CCBD6"
        />
        <path
          d="M219.669 87.2289L203.371 115.494L187.073 87.2289H219.669Z"
          fill="#1F68B5"
        />
        <path
          d="M156.737 41.8295H204.979L180.858 83.6667L156.737 41.8295Z"
          fill="#21BAB7"
        />
      </g>
      <defs>
        <clipPath id="clip0_1_417">
          <rect
            width={326}
            height={167}
            fill="white"
            transform="matrix(-1 0 0 1 326 0)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Frame;
