import { useLocation, ScrollRestoration, Outlet } from "react-router-dom";
import { popupProvider } from "contextProviders/popupProvider";
import { giftProvider } from "contextProviders/giftProvider";
import { useState } from "react";
import Popup from "components/Popup";
import { Provider } from "react-redux";
import { store } from "redux/store";
import Wrapper from "components/Wrapper";
import { CookiesProvider } from "react-cookie";
import DesktopScreen from "components/DesktopScreen";
import { useMedia } from "react-use";

const popupInitialContent = {
  title: "",
  icon: null,
  acceptButton: null,
  cancelButton: null,
  children: null,
};

function Root({ children }) {
  const [popupOpen, setPopupOpen] = useState(false);
  const [popupContent, setPopupContent] = useState(popupInitialContent);
  const [gift, setGift] = useState(null);
  const { pathname } = useLocation();
  const [visible, setVisible] = useState(true);
  const desktop = useMedia("(min-width: 768px)");

  const toggleVisibility = () => setVisible(false);

  return (
    <CookiesProvider>
      <Provider store={store}>
        <giftProvider.Provider
          value={{
            gift,
            setGift,
          }}
        >
          <popupProvider.Provider
            value={{
              popupOpen,
              setPopupOpen,
              popupContent,
              setPopupContent,
            }}
          >
            {visible && <DesktopScreen toggleVisibility={toggleVisibility} />}
            {(!desktop || !visible) && (
              <Wrapper>
                <Popup
                  open={popupOpen}
                  setOpen={setPopupOpen}
                  popupContent={popupContent}
                />
                <Outlet />

                <ScrollRestoration
                  getKey={(location, matches) => {
                    return location.pathname;
                  }}
                />
              </Wrapper>
            )}
          </popupProvider.Provider>
        </giftProvider.Provider>
      </Provider>
    </CookiesProvider>
  );
}

export default Root;
