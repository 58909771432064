import { sha256 } from "js-sha256";

export const handleRedirect = (phone) => {
  const hostName = "https://receipt-vyhodnoi.jetcrm.ru/";
  const currentPage = window.location.href;
  const salt = "MogQg17mYF2TX";

  window.location.href = `${hostName}?msisdn=${phone}&mall_id=1&signature=${sha256(
    phone + salt
  )}&redirect=${encodeURIComponent(currentPage)}`;
};
