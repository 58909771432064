import React from "react";
import SectionTitle from "components/titles/SectionTitle";
import PrimaryButton from "components/buttons/PrimaryButton";
import { ROUTES_PATH } from "constants/routesPath";
import MemberFrame from "icons/MemberFrame";

const BecomeMember = () => {
  return (
    <section className="become-member">
      <div className="container">
        <div className="become-member__content">
          <div className="become-member__left">
            <SectionTitle
              text={"Стань участником"}
              extraClass={"become-member__title"}
            />
            <p className="become-member__text">
              Стань участником, получай бонусы за покупки и обменивай на
              комплименты, скидки, подарки!
              <div>
                За каждые 10 руб. начисляется 1 бонус! Принимаются чеки от 500
                руб.
              </div>
            </p>
            <PrimaryButton
              text={"Стать участником"}
              href={ROUTES_PATH.REGISTRATION}
            />
          </div>
        </div>
        <div className="become-member__right">
          <MemberFrame />
        </div>
      </div>
    </section>
  );
};

export default BecomeMember;
