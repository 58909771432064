import React, { useState } from "react";
import Logo from "icons/Logo";
import Burger from "components/Burger";
import { ROUTES_PATH } from "constants/routesPath";
import { Link, useLocation } from "react-router-dom";
import Frame from "icons/Frame";

const menuItems = [
  {
    label: "Главная",
    href: "https://vykhodnoy.ru/",
  },

  {
    label: "Магазины",
    href: "https://vykhodnoy.ru/shops/",
  },

  {
    label: "Еда",
    href: "https://vykhodnoy.ru/tag/kafe-i-restorany/",
  },

  {
    label: "Детям",
    href: "https://vykhodnoy.ru/tag/dlya-detej/",
  },

  {
    label: "Услуги",
    href: "https://vykhodnoy.ru/tag/uslugi/",
  },

  {
    label: "Парковка",
    href: "https://vykhodnoy.ru/parkovka/",
  },

  {
    label: "Кинотеатр",
    href: "https://vykhodnoy.ru/sinema/",
  },
];

const Hero = () => {
  const { pathname } = useLocation();
  const [menuActive, setMenuActive] = useState(false);

  const toggleMenuActive = () => {
    setMenuActive(!menuActive);
    document.body.classList.toggle("lock");
  };

  return (
    <section className="hero">
      <div className="container">
        <Burger isAcive={menuActive} callback={toggleMenuActive} />
        <ul className={`hero__menu ${menuActive ? "hero__menu--active" : ""}`}>
          {menuItems.map((item, key) => (
            <li
              className="hero__menu-item"
              key={key}
              onClick={toggleMenuActive}
            >
              <a href={item.href} target="_blank">
                {item.label}
              </a>
            </li>
          ))}
        </ul>

        {pathname !== ROUTES_PATH.HOME ? (
          <Link to={ROUTES_PATH.HOME} className="hero__logo">
            <Logo />
          </Link>
        ) : (
          <div className="hero__logo">
            <Logo />
          </div>
        )}

        <div className="hero__frame">
          <Frame />
        </div>
      </div>
    </section>
  );
};

export default Hero;
